import React, { useState, useEffect, useCallback } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Tooltip, CircleMarker, Popup } from "react-leaflet";
import MapPage01 from "../../components/maps/Map01";

const MapDisplay = ({ projects, center, setCenter, zoom, setZoom, layers, isMobileOnly }) => 
{
  const [markerList01, setMarkerList01] = useState(null);
  const [markerList02, setMarkerList02] = useState(null);

  const clickMarker = useCallback((project) => 
  {
    setCenter(project.location.coordinates);
  }, [setCenter]); 

  const zoomMap = (e) =>
  {    
    let coordinates = [e.target.getCenter().lat.toFixed(6), e.target.getCenter().lng.toFixed(6)];

    setCenter(coordinates);
    setZoom(e.target.getZoom());
  };

  const dragMap = (e) =>
  {
    let coordinates = [e.target.getCenter().lat.toFixed(6), e.target.getCenter().lng.toFixed(6)];

    setCenter(coordinates);
  };

  /* MARKERS */
  useEffect(() => 
  {
    if (typeof window !== 'undefined') 
    {
      let markerArray01 = projects.map((project, i) => 
      {        
        return (
          <CircleMarker
            key = {i}
            center = {project.location.coordinates}
            radius = {12}
            fill = "true"
            color = '#fff'
            fillColor = {project.info.available > 0 ? "#11a20f" : "#fc5501"}
            opacity = {1.0}
            fillOpacity = {1.0}
            weight = {2}

            eventHandlers = 
            {{ 
              click: () => clickMarker(project) 
            }}
          >
            <Popup
              //onClose = {() => closePopup(info.id)}
              autoPan = {false}
              minWidth = {window.innerWidth * 0.90 > 300 ? 300 : window.innerWidth * 0.90}
            >
              <div
                className = "space-y-1 text-base leading-5 text-gray-600"
              >
                <span
                  className = "text-gray-900 font-semibold text-lg"
                >
                  {project.meta.title}
                </span>

                <GatsbyImage 
                  className = "" 
                  image = {getImage(project.thumbnail.childrenImageSharp[0])}
                  style = 
                  {{ 
                    height: '100%', 
                    width: '100%', 
                  }} 
                  alt = {project.meta.title}
                />

                <a
                  className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
                  target = "_blank"
                  href = {'/' + project.slug}
                  rel="noreferrer"
                >
                  VER PROYECTO
                </a>
              </div>
            </Popup>
          </CircleMarker>
        )
      });
      
      let markerArray02= projects.map((project, i) => 
      {
        return (
          <CircleMarker
            key = {i}
            center = {project.location.coordinates}
            radius = {12}
            fill = "true"
            color = '#fff'
            fillColor = {project.info.available > 0 ? "#11a20f" : "#fc5501"}
            opacity = {1.0}
            fillOpacity = {1.0}
            weight = {2}

            eventHandlers = 
            {{ 
              click: () => clickMarker(project) 
            }}
          >
            <Tooltip direction = "right" offset = {[10, -5]} permanent = {true}>{`${project.info.name.toUpperCase()}`}</Tooltip>

            <Popup
              //onClose = {() => closePopup(info.id)}
              autoPan = {false}
              minWidth = {window.innerWidth * 0.90 > 300 ? 300 : window.innerWidth * 0.90}
            >
              <div
                className = "space-y-1 text-base leading-5 text-gray-600 select-none"
              >
                <h2 className = "flex justify-center text-base md:text-xl lg:text-2xl font-semibold font-mono uppercase my-2">
                  {project.meta.title}
                </h2>

                <GatsbyImage 
                  className = "" 
                  image = {getImage(project.thumbnail.childrenImageSharp[0])}
                  style = 
                  {{ 
                    height: '100%', 
                    width: '100%', 
                  }} 
                  alt = {project.meta.title}
                /> 
                
                <a className = "pt-1 text-base font-semibold flex justify-center"
                  target = "_blank"
                  rel = "noreferrer"
                  href = {'/' + project.slug}
                >
                  <button 
                    className = "bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded"
                  >
                    VER PROYECTO
                  </button>
                </a>
              </div>
            </Popup>
          </CircleMarker>
        )
      });

      setMarkerList01(markerArray01);
      setMarkerList02(markerArray02);
    }    
  }, [projects, clickMarker]);

  return(
    <MapPage01
      list01        = {markerList01}
      list02        = {markerList02}

      center        = {center}
      zoom          = {zoom}
      menuHeight    = {7}
      layers        = {layers}

      dragMap       = {dragMap}
      zoomMap       = {zoomMap}

      isMobileOnly      = {isMobileOnly}
    />  
  )
}

export default MapDisplay