import React from "react";
import PropTypes from "prop-types"

import Navbar03 from "./navbars/Navbar03";
import Menu01 from "./menus/Menu01";
import Sidemenu01 from "./sidemenus/Sidemenu01";

const Layout = ({ children, menuHeight, clickLocation, clickLayer, clickInfo,  isMap, sector, setIsMap, center, existentSectors, isMobileOnly }) => 
{
  return (
    <>
      <div
        style = 
        {{
          margin: `0`,
        }}
      >
       <Navbar03 
        siteTitle = 'inmovisor'
        isMobileOnly = {isMobileOnly}
      />

        <main>{children}</main>

        <Menu01          
          menuHeight    = {menuHeight}
          clickLocation = {clickLocation}
          sector        = {sector}

          isMap         = {isMap}
          setIsMap      = {setIsMap}

          center        = {center}

          existentSectors = {existentSectors}
        />

        {isMap &&
          <Sidemenu01
            bottom        = {menuHeight}
            clickLayer    = {clickLayer}
            clickInfo     = {clickInfo}
          />
        }
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
