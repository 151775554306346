import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { isMobileOnly } from 'react-device-detect';

import Layout from "../components/layout";
import Seo from "../components/seo";

import ProjectsGallery from "../components/main/gallery";
import MapDisplay from "../components/main/map";

const IndexPage = ({ data }) => 
{
  const projects = data.allProjectsJson.nodes; 

  const [layers, setLayers] = useState(true);
  const [info, setInfo] = useState(true);

  const [center, setCenter] = useState(isMobileOnly ? ['-0.216442', '-78.489761'] : ['-0.188031', '-78.479059']); 
  const [zoom, setZoom] = useState(isMobileOnly ? 11 : 14);  

  const [sector, setSector] = useState("allquito");
  const [isMap, setIsMap] = useState(false);

  const [existentSectors, setExistentSectors] = useState(false);


 /* LISTS CHECK - LOCATIONS */
 useEffect(() => 
  {
    const existentSectorsSet = new Set();

    existentSectorsSet.add('allquito');

    // Iterar sobre los elementos y agregar valores únicos al conjunto
    projects.forEach(elemento => 
    {
      existentSectorsSet.add(elemento.location.sector);
    });

    const existentSectorsArray = Array.from(existentSectorsSet);

    setExistentSectors(existentSectorsArray);

  }, [projects]);

  const clickLayer = () =>
  {
    setLayers(!layers);
  };

  const clickInfo = () =>
  {
    setInfo(!info);
  };

  const clickLocation = (location, zoom, sector) =>
  {
    setCenter(location);
    setZoom(zoom);
    setSector(sector);
  }; 

  return(
    <Layout
      clickLocation     = {clickLocation}
      clickLayer        = {clickLayer}
      clickInfo         = {clickInfo}
      menuHeight        = {7}

      layers            = {layers}
      info              = {info}

      sector            = {sector}
      center            = {center}

      isMap             = {isMap}
      setIsMap          = {setIsMap}

      existentSectors   = {existentSectors}

      isMobileOnly      = {isMobileOnly}
    >
      <Seo 
        title = "Inmovisor"
        keywords  = {["apartamento", "departamento", "propiedad" ,"propiedades", "arriendo", "venta", "suite", "ecuador", "quito", "suite", "local"]}
      />

      {!isMap &&
        <ProjectsGallery
          sector      = {sector}
          projects    = {projects}

          isMobileOnly      = {isMobileOnly}
        />
      }

      {isMap &&
        <MapDisplay          
          projects    = {projects}
          center      = {center}
          setCenter   = {setCenter}
          zoom        = {zoom}
          setZoom     = {setZoom}

          layers        = {layers}

          isMobileOnly      = {isMobileOnly}
        />
      }
      
    </Layout>
  )
}

//Projects Query
export const query = graphql`
query ProjectsQuery {
  allProjectsJson(filter: {visible: {eq: true}}) {
    nodes {
      slug
      thumbnail {
        childrenImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
              transformOptions: {cropFocus: CENTER}
            webpOptions: {quality: 60}
            jpgOptions: {progressive: true, quality: 60}
            blurredOptions: {width: 10}
            formats: WEBP
            aspectRatio: 1.91
            width: 400
            breakpoints:[320,480,640, 1024,1920]
          )
        }
      }
      info {
        name
        biz
        bizUserName
        available
      }
      links {
        webpage
      }
      location {
        google
        waze
        coordinates
        gsv
        sector
      }
      preview {
        image
      }
      sales {
        whatsapp
      }
      meta {
        title
        description
        image
      }
    }
  }
}
`

export default IndexPage