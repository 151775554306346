import React from "react";
import PropTypes from "prop-types";

//components
import Thumbnail from "../thumbnail/Thumbnail02";

function ProjectsGallery({ projects, sector,  isMobileOnly }) 
{
  return (
    <section className = "block select-none bg-slate-200">
     <section className = {`h-screen max-w-7xl px-8 m-auto pb-[40px] ${isMobileOnly ? "pt-[40px]" : "pt-[80px]"} `}>
        <div className = "grid justify-center md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-7 my-10">
          
          {projects.map( (project, i) => 
            {
              if(sector === "allquito")
                {
                  return(
                    <Thumbnail
                      key = {i}
                      data = {project}
                    />
                  )
                }
              else
                {
                  if(sector === project.location.sector)
                    {
                      return(
                        <Thumbnail
                          key = {i}
                          data = {project}
                        />
                      )
                    }
                    else
                    {
                      return null
                    }
                }
            })}

        </div>
      </section>
    </section>
  )
}

ProjectsGallery.propTypes = {
  siteTitle: PropTypes.string,
}

ProjectsGallery.defaultProps = {
  siteTitle: ``,
}

export default ProjectsGallery