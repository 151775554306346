import PropTypes from "prop-types";
import React from "react";

import sectorsDB from "../../databases/components/sectors.json";

function Sidemenu01({ clickLocation, sector, center, existentSectors }) 
{
  return (
    <div>
      <h2 className = "flex justify-center mb-1 text-3xl text-blue-600 font-bold select-none">
        QUITO
      </h2>
      
      {sectorsDB.list.map((item, i) => 
      {
        const existe = existentSectors.includes(item.id);
               
        if(existe)
          {
           return(
            <button 
              className = {`h-[40px] w-[300px] flex justify-center font-semibold border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:bg-sky-500 hover:text-white ${sector === item.id ? "bg-sky-900 text-white" : "bg-transparent text-gray-800" }`}
              onClick   = {() => clickLocation(item.location, item.zoom, item.id)}
              key = {i}
            >
              <span 
                className = 'flex items-center m-0 text-xl uppercase h-full'
              >
                {item.text}
              </span>
            </button>
           )
          }
          else
          {
            return null
          }
        
      })}

    </div>
  )
}

Sidemenu01.propTypes = {
  siteTitle: PropTypes.string,
}

Sidemenu01.defaultProps = {
  siteTitle: ``,
}

export default Sidemenu01