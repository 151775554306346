import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

function Thumbnail02({ data }) 
{
  return (
    <Link 
      to = {`/${data.slug}`}
      href = "#responsive-header"
      target = "blank"
      className = "bg-white rounded-lg border shadow-md overflow-hidden hover:border-grey-400 hover:border-1 hover:shadow-grey-500/50 hover:shadow-xl hover:scale-110 ease-in duration-100 max-w-[400px]"
    >
      <GatsbyImage 
        image = {getImage(data.thumbnail.childrenImageSharp[0])}
        alt = {data.slug}
      />
      
      <div className = "p-2 flex justify-center my-0">                
        <h2 className = "font-bold text-xl leading-6 text-gray-700 my-1 uppercase">
          {data.info.name}

          &nbsp;

          -
          
          &nbsp;

          {data.info.biz}
        </h2>       
      </div>
    </Link>
  )
}

Thumbnail02.propTypes = {
  siteTitle: PropTypes.string,
}

Thumbnail02.defaultProps = {
  siteTitle: ``,
}

export default Thumbnail02