//Dependencies
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";

function ChangeCenter({ center, zoom }) 
{
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

const MapPage01 = (props) =>
{
  const layer1 = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
  const layer2 = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  const {center, layers, list01, list02, menuHeight, zoom, dragMap, zoomMap, isMobileOnly} = props;

  const [markerList, setMarkerList] = useState();

  /* MARKERS - TOOLTIPS */
  useEffect(() => 
  {
    if(zoom >= 11)
    {
      setMarkerList(list02);
    }
    else
    {
      setMarkerList(list01);
    }
  }, [list01, list02, zoom]);
  
  const MapContent = ({ onClick }) => 
  {  
    const map = useMapEvents
    ({          
      zoomend : event => zoomMap(event),
      dragend : event => dragMap(event),
      center : event =>  map.getCenter(event),
    }) 

    return null;
  }

  if (typeof window !== 'undefined') 
  {
    return (
      <div
        style = {{height : (100 - menuHeight) + 'vh', width : '100%', paddingTop : isMobileOnly ? 60 : 80, paddingBottom : 0 }}
        className = {'select-none'}
      >
        {layers &&
          <MapContainer 
            center = {center} 
            zoom = {zoom} 
            scrollWheelZoom = {true}
            style = {{height : '100%', width : '100%'}}

            onDragEnd = {event => dragMap(event)}
          >
            <ChangeCenter center = {center}  zoom = {zoom}/> 
            <MapContent/> 

            <TileLayer
              noWrap = {true}
              url = {layer2}
              minZoom = {5}
              maxZoom = {19}
            />
            
            {markerList}

          </MapContainer>
        }

        {!layers &&
          <MapContainer 
            center = {center} 
            zoom = {zoom} 
            scrollWheelZoom = {true}
            style = {{height : '100%', width : '100%'}}
            onDragEnd={event => dragMap(event)}
            eventHandlers =
            {{
              dragend:() => dragMap(),
              click: () => dragMap() 
            }}
          >
            <ChangeCenter center = {center} /> 
            <MapContent/> 
            
            <TileLayer
              noWrap={true}
              url = {layer1}
              minZoom = {5}
              maxZoom = {19}
            />
            
            {markerList}

          </MapContainer>
        }
      </div>
    )
  }
  return null;
}

export default MapPage01;