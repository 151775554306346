//Dependencies
import React, { useEffect, useState, useCallback } from "react";

function Navbar03({ siteTitle, isMobileOnly }) 
{ 
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = useCallback(() => {
    if (typeof window !== 'undefined') 
    { 
      if (window.scrollY > lastScrollY) 
      { // if scroll down hide the navbar
        setShow(false); 
      } else 
      { // if scroll up show the navbar
        setShow(true);  
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  }, [lastScrollY]);

  useEffect(() => 
  {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [controlNavbar, lastScrollY]);

  return (
    <div 
      className = {`justify-content fixed flex w-screen flex-col items-center shadow-xl transition-all duration-500 ease-in-out bg-white select-none border-b border-solid border-black z-[1000] ${isMobileOnly ? "h-[60px]" : "h-[80px]" }`}
      style = {{top : show ? 0 : -80}}
    >
      <nav className = "flex flex-1 flex-row items-center">
        <div className = "flex flex-row">

          <h1 className = "lg:text-5xl text-4xl text-center text-black font-extrabold uppercase leading-5 my-auto">
            {siteTitle}
          </h1>
                       
        </div>
      </nav>
    </div>
  )
}

export default Navbar03;