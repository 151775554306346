import { Affix } from 'antd';
import PropTypes from "prop-types";
import React from "react";
import { /*InfoFilledIcon, */ LayerFilledIcon } from '../../assets/icons/icons';

function Sidemenu01({ bottom, clickLayer /*, clickInfo*/ }) 
{
  return (
    <Affix 
      style = {{ position: "fixed", bottom: bottom + 'vh', right: 10, zIndex : 1000}}
    >
      <div>
        <button 
          className = "flex items-center justify-center text-white p-3 text-center mb-2 md:mb-4 lg:mb-5 lg:w-16 lg:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() =>clickLayer()}
        >
          <LayerFilledIcon className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
        </button>
       
        {/* 
        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-2 md:mb-4 lg:mb-5 lg:w-16 lg:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => clickInfo()}
        >
          <InfoFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
        */}
      </div>
    </Affix>
  )
}

Sidemenu01.propTypes = {
  siteTitle: PropTypes.string,
}

Sidemenu01.defaultProps = {
  siteTitle: ``,
}

export default Sidemenu01
