import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Popover } from 'antd';
import Popover01 from '../popovers/Popover01';

function Menu01({ menuHeight, clickLocation, sector, center, isMap, setIsMap, existentSectors}) 
{
  return (
    <footer 
      className = {` fixed bottom-0 left-0 flex justify-center items-center bg-white text-gray-800 w-screen border-t-2 border-slate-500 drop-shadow-xl z-[1000]`}
      style = {{height : menuHeight + 'vh'}}
    >
      <div className = ' w-[350px] flex justify-center items-center h-full '>
        <Link
          className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
          to        = {'/'}
          aria-label = "refreshes this page"
        >          
          <svg 
            width = "1em" height = "1em" fill = "currentColor" 
            className = " text-3xl lg:text-4xl flex "
            xmlns = "http://www.w3.org/2000/svg" 
            viewBox = "0 0 576 512"
          >
            <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
          </svg>
        </Link>

        <button 
          className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600"
          aria-label = "location"
        >
          <Popover
            content = 
            {
              () => <Popover01 
                      clickLocation = {clickLocation}
                      sector =  {sector}
                      center = {center}

                      existentSectors = {existentSectors}
                    />
            }
            trigger = "click"
          >
            <div
              className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
            >
              <svg 
                width = "1em" height = "1em" fill = "currentColor" 
                className = " text-3xl lg:text-4xl flex "
                xmlns = "http://www.w3.org/2000/svg" 
                viewBox = "0 0 384 512"
              >
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
              </svg>
            </div>
          </Popover>
        </button>

        <button
          className = {`flex flex-1 justify-center items-center hover:text-sky-600 cursor-pointer ${isMap ?  "text-green-500" : " text-gray-900"}`}
          onClick   = {() => {setIsMap(!isMap)}}
          aria-label = "map"
        >
          <svg 
            width = "1em" height = "1em" fill = "currentColor" 
            className = " text-3xl lg:text-4xl flex "
            xmlns = "http://www.w3.org/2000/svg" 
            viewBox = "0 0 576 512"
          >
            <path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
          </svg>
        </button>
      </div>
    </footer>
  )
}

Menu01.propTypes = {
  siteTitle: PropTypes.string,
}

Menu01.defaultProps = {
  siteTitle: ``,
}

export default Menu01
